.heroWrapper {
  @media (--tablet-l) {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  position: relative;
}

.textWrapper {
  margin: var(--spacing-5) 0 var(--spacing-8) 0;
  @media (--tablet-l) {
    flex: 1;
    max-width: 50%;
  }
}

.mobileImageContainer {
  margin: -12px 0 -20px 0;

  & img {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 200px;
  }

  @media (--tablet-l) {
    display: none;
  }
}

.imageContainer {
  display: none;

  & img {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 200px;
  }

  @media (--tablet-l) {
    margin: var(--spacing-6) 0 0 0;
    display: block;
    flex: 1;
    max-width: 50%;
  }
}

.formWrapper {
  width: 100%;
  margin-top: -110px;
  background-color: white;
  z-index: 1;
  border-bottom: 1px solid var(--neutral-200, #cdcdcd);
}

& [data-tenant='rac'] {
  & .formWrapper {
    margin-top: -150px;
    @media (--mobile) {
      margin-top: -100px;
    }
  }
  & .imageWrapper {
    /* position: absolute;
    top:0;
    left: 0; */
    order: -1;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & .imageContainer {
    display: flex;
    margin: 0;
    max-width: 100vw;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    height: 380px;

    @media (--mobile) {
      height: 240px;
    }

    & img {
      width: 100vw;
      height: auto;
      object-fit: cover;
      object-position: center;
      height: 380px;
      max-height: none;
      max-width: none;
    }
  }

  & .heroWrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 380px;

    @media (--mobile) {
      height: 240px;
    }
  }

  & .textWrapper {
    color: white;

    padding: 0;
    margin: 0;

    margin-left: var(--spacing-20);
    padding-bottom: var(--spacing-20);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    position: relative;

    @media (--mobile) {
      height: 240px;
      margin-left: var(--spacing-10);
      padding-bottom: var(--spacing-10);
    }

    & span {
      background: var(--color-primary-500);
      padding: var(--spacing-1) var(--spacing-2);
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }

    & .heading {
      margin-bottom: var(--spacing-2);
    }

    & .subHeading {
      margin-top: 0;
    }
  }

  & .mobileImageContainer {
    display: none;
  }
}
